import { vmpInstance as inst } from "./instance"

type objectType = 'premises' | 'equipment' | 'systems' | 'processes'

export const vmpAPI = {
    getVMPData(tablename: string, year: string) {
        return inst.get(`getVMPData.php?tablename=${tablename}&year=${year}`).then(res => res.data)
    },
    getObjectVMPPlansData(objectId: string, sp: string, objectType: objectType) {
        return inst.post(`getObjectVMPPlansData.php`, { objectId, sp, objectType }, {}).then(res => res.data)
    },
    getObjectNextYearVMPPlansData(objectId: string, sp: string, objectType: objectType) {
        return inst.post(`getObjectNextYearVMPPlansData.php`, { objectId, sp, objectType }, {}).then(res => res.data)
    },
    updateVMPPlansData(daysCount: number, month: number, recordId: string, sp: string, objectName: string) {
        return inst.post(`updateVMPPlansData.php`, { daysCount, month, recordId, sp, objectName }, {}).then(res => res.data)
    },
    updateVMPPlansNextYearData(daysCount: number, month: number, recordId: string, sp: string, objectName: string) {
        return inst.post(`updateVMPPlansNextYearData.php`, { daysCount, month, recordId, sp, objectName }, {}).then(res => res.data)
    },
    createVMPPlansData(objectName: string, objectId: string, sp: string, typeval: string, objectType: objectType) {
        return inst.post(`createVMPPlansData.php`, { objectName, objectId, sp, typeval, objectType }, {}).then(res => res.data)
    },
    createNextYearVMPPlansData(objectName: string, objectId: string, sp: string, typeval: string, objectType: objectType) {
        return inst.post(`createNextYearVMPPlansData.php`, { objectName, objectId, sp, typeval, objectType }, {}).then(res => res.data)
    },
    getVMPChangeList(vmpId: string, vmpYear: string) {
        return inst.post(`getVMPChangeList.php`, { vmpId, vmpYear }, {}).then(res => res.data)
    },
    updateChangeListData(id: string, text: string, type: string) {
        return inst.post(`updateChangeListData.php`, { id, text, type }, {}).then(res => res.data)
    },
    startAutoCreateGraph(tablename: string, year: string) {
        return inst.post(`startAutoCreateGraph.php`, { tablename, year }, {}).then(res => res.data)
    },
}
