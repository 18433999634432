import { plansInstance as inst } from "./instance"

export const plansAPI = {
    getMonthList() {
        return inst.get(`getMonthList.php`).then(res => res.data)
    },
    getPlans(month: string) {
        return inst.get(`getPlans.php?month=${month}`).then(res => res.data)
    },
    updatePlansFio(fio: string, objectId: string, tableName: string, recordId: string, month: string) {
        return inst.post(`updatePlansFio.php`, { fio, objectId, tableName, recordId, month }, {}).then(res => res.data)
    },
    updatePlansDoc(doc: string, objectId: string, tableName: string, recordId: string, month: string) {
        return inst.post(`updatePlansDoc.php`, { doc, objectId, tableName, recordId, month }, {}).then(res => res.data)
    },
    updatePlansDates(startDate: string, endDate: string, objectId: string, tableName: string, recordId: string, month: string) {
        return inst.post(`updatePlansDates.php`, { startDate, endDate, objectId, tableName, recordId, month }, {}).then(res => res.data)
    },
    deletePlans(recordId: string, objectId: string, tableName: string, month: string) {
        return inst.post(`deletePlans.php`, { recordId, objectId, tableName, month }, {}).then(res => res.data)
    },
    updateReportStatus(status: string, objectId: string, tableName: string, recordId: string, month: string) {
        return inst.post(`updateReportStatus.php`, { status, objectId, tableName, recordId, month }, {}).then(res => res.data)
    },
    vmpImport(month: string, year: string, monthYear: string) {
        return inst.post(`vmpImport.php`, { month, year, monthYear }, {}).then(res => res.data)
    },
}