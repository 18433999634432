import { ThunkAction } from "redux-thunk"
import { AppStateType, InferActionsTypes } from "../store"
import { logout } from "./authReducer"
import { adminAPI } from "../../api/adminAPI"
import { getAllUsers } from "./appReducer"
import { UseFormSetError } from "react-hook-form"

type ResponseType = {
    resultCode: number
    message: string
}

export type UsersType = {
    id: string
    login: string
    fio: string
    position: string
    sp: string
    access: 1 | 2 | 3 | 4 | 5
    time: string
    del: string
    block: string
}

const initialState = {
    responseMessage: null as ResponseType | null
}

type InitialStateType = typeof initialState
export const adminReducer = (state = initialState, action: ActionTypes): InitialStateType => {
    switch (action.type) {
        case 'admin/SET_RESPONSE_MESSAGE':
            return { ...state, responseMessage: action.data }
        default:
            return state
    }
}

export const setLockUser = (userId: string, editType: 'lock' | 'unlock'): ThunkType => async (dispatch) => {
    let data = await adminAPI.setLockUser(userId, editType)
    if (data.resultCode === 0) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
        dispatch(getAllUsers())
    } else if (data.resultCode === 1) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
    } else if (data.resultCode === 2) {
        dispatch(logout())
    }
}

export const resetUserPassword = (userId: string): ThunkType => async (dispatch) => {
    let data = await adminAPI.resetUserPassword(userId)
    if (data.resultCode === 0) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
        dispatch(getAllUsers())
    } else if (data.resultCode === 1) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
    } else if (data.resultCode === 2) {
        dispatch(logout())
    }
}

export const activateUser = (userId: string, editType: 'activate' | 'deactivate'): ThunkType => async (dispatch) => {
    let data = await adminAPI.activateUser(userId, editType)
    if (data.resultCode === 0) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
        dispatch(getAllUsers())
    } else if (data.resultCode === 1) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
    } else if (data.resultCode === 2) {
        dispatch(logout())
    }
}

export const editUserData = (userId: string, editType: 'fio' | 'position', text: string): ThunkType => async (dispatch) => {
    let data = await adminAPI.editUserData(userId, editType, text)
    if (data.resultCode === 0) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
        dispatch(getAllUsers())
    } else if (data.resultCode === 1) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
    } else if (data.resultCode === 2) {
        dispatch(logout())
    }
}

export const createNewUser = (login: string, fio: string, position: string, sp: string, access: 1 | 2 | 3 | 4 | 5, setError: UseFormSetError<UsersType>): ThunkType => async (dispatch) => {
    let data = await adminAPI.createNewUser(login, fio, position, sp, access)
    if (data.resultCode === 0) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
        dispatch(getAllUsers())
    } else if (data.resultCode === 1) {
        dispatch(adminActions.setResponseMessage({ resultCode: data.resultCode, message: data.messages[0] }))
        setError('login', { message: data.message })
    } else if (data.resultCode === 2) {
        dispatch(logout())
    }
}

type ActionTypes = InferActionsTypes<typeof adminActions>
type ThunkType = ThunkAction<void, AppStateType, unknown, ActionTypes>

export const adminActions = {
    setResponseMessage: (data: ResponseType | null) => ({ type: 'admin/SET_RESPONSE_MESSAGE', data } as const),
}