import { adminInstance as inst } from "./instance"

export const adminAPI = {
    setLockUser(userId: string, editType: 'lock' | 'unlock') {
        return inst.post(`setLockUser.php`, { userId, editType }, {}).then(res => res.data)
    },
    resetUserPassword(userId: string) {
        return inst.post(`resetUserPassword.php`, { userId }, {}).then(res => res.data)
    },
    activateUser(userId: string, editType: 'activate' | 'deactivate') {
        return inst.post(`setActivateUser.php`, { userId, editType }, {}).then(res => res.data)
    },
    editUserData(userId: string, editType: 'fio' | 'position', text: string) {
        return inst.post(`editUserData.php`, { userId, editType, text }, {}).then(res => res.data)
    },
    createNewUser(login: string, fio: string, position: string, sp: string, access: 1 | 2 | 3 | 4 | 5) {
        return inst.post(`createNewUser.php`, { login, fio, position, sp, access }, {}).then(res => res.data)
    }
}