import { useLocation, useNavigate } from "react-router-dom"
import { AppDispatch } from "./redux/store"
import { useDispatch, useSelector } from "react-redux"
import { getIsAuthSelector } from "./redux/Selectors/authSelectors"
import { loginOfCookieData } from "./redux/Reducers/authReducer"
import { getAppErrorMessage, getAppLoadingMessage, getAppSuccessMessage, getInitializeAppStatus, getTermSettingsSelector, getThemeType } from "./redux/Selectors/appSelectors"
import { appActions, getLabelTermSettings, setTheme } from "./redux/Reducers/appReducer"
import { useEffect } from "react"
import { App } from "./App"
import { message } from "antd"
import { setCookie } from "./components/common/cookie"

export const AppContainer: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch()
    const isAuth = useSelector(getIsAuthSelector)
    const initializeAppStatus = useSelector(getInitializeAppStatus)
    const themeType = useSelector(getThemeType)
    const labelTerm = useSelector(getTermSettingsSelector)

    const { theme } = require('antd/lib')

    const handleThemeChange = (checked: boolean) => {
        if (checked) {
            dispatch(setTheme('light'))
            setCookie('theme', 'light', 30)
        } else {
            dispatch(setTheme('dark'))
            setCookie('theme', 'dark', 30)
        }
    }

    // возможно надо тут прописать сразу все настройки для инициализации
    useEffect(() => {
        if (isAuth) {
            !labelTerm && dispatch(getLabelTermSettings())
        }
    }, [isAuth])

    const errorMessage = useSelector(getAppErrorMessage)
    const successMessage = useSelector(getAppSuccessMessage)
    const loadingMessage = useSelector(getAppLoadingMessage)

    const [messageApi, contextHolder] = message.useMessage()

    useEffect(() => {
        if (errorMessage) {
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 7
            })
            dispatch(appActions.setErrorMessage(null))
        } else if (loadingMessage) {
            messageApi.open({
                type: 'loading',
                content: loadingMessage
            })
        } else if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 7
            })
            dispatch(appActions.setSuccessMessage(null))
        }
    }, [errorMessage, successMessage, loadingMessage])

    useEffect(() => {
        !isAuth && dispatch(loginOfCookieData())
    }, [])

    useEffect(() => {
        if (initializeAppStatus) {
            if (!isAuth) {
                navigate('/login')
            } else {
                (location.pathname === '/') && navigate('/work');
                (location.pathname === '/login') && navigate('/work')
            }
        }        
    }, [isAuth, initializeAppStatus, location.pathname])

    return <App contextHolder={contextHolder} theme={theme} themeType={themeType} handleThemeChange={handleThemeChange} />
}