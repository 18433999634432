import axios, { InternalAxiosRequestConfig } from 'axios'
import { getCookie } from '../components/common/cookie'

const server = 'https://validcontrol.ru'; // для глобальной версии
// const server = 'http://10.85.10.212/ov'; // для версии КФК
// const server = 'http://10.85.10.212:82/ov'; // для тест сервера
// const server = 'http://10.85.10.212/testApi'; // для тест сервера

const setAuthHeader = (config: InternalAxiosRequestConfig<any>) => {
    const token = getCookie('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}

export const authInstance = axios.create({ baseURL: server + '/api/auth/' })
export const equipInstance = axios.create({ baseURL: server + '/api/equip/' })
export const premInstance = axios.create({ baseURL: server + '/api/prem/' })
export const appInstance = axios.create({ baseURL: server + '/api/common/' })
export const adminInstance = axios.create({ baseURL: server + '/api/admin/' })
export const instInstance = axios.create({ baseURL: server + '/api/inst/' })
export const procInstance = axios.create({ baseURL: server + '/api/proc/' })
export const plansInstance = axios.create({ baseURL: server + '/api/plans/' })
export const sysInstance = axios.create({ baseURL: server + '/api/sys/' })
export const workInstance = axios.create({ baseURL: server + '/api/work/' })
export const vmpInstance = axios.create({ baseURL: server + '/api/vmp/' })

authInstance.interceptors.request.use(config => setAuthHeader(config))
equipInstance.interceptors.request.use(config => setAuthHeader(config))
premInstance.interceptors.request.use(config => setAuthHeader(config))
appInstance.interceptors.request.use(config => setAuthHeader(config))
adminInstance.interceptors.request.use(config => setAuthHeader(config))
instInstance.interceptors.request.use(config => setAuthHeader(config))
procInstance.interceptors.request.use(config => setAuthHeader(config))
plansInstance.interceptors.request.use(config => setAuthHeader(config))
sysInstance.interceptors.request.use(config => setAuthHeader(config))
workInstance.interceptors.request.use(config => setAuthHeader(config))
vmpInstance.interceptors.request.use(config => setAuthHeader(config))