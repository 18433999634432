import { appInstance as inst } from "./instance"

export const paperplanesAPI = {
    getPaperplanes() {
        return inst.get(`getPaperplanes.php`).then(res => res.data)
    },
    deletePaperplanes(id: string) {
        return inst.post(`deletePaperplanes.php`, { id }, {}).then(res => res.data)
    },
    createPaperplanes(file: any) {
        const formData = new FormData()
        formData.append("image", file)
        return inst.post(`createPaperplanes.php`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
    },
    setPaperplanesDescription(id: string, description: string) {
        return inst.post(`setPaperplanesDescription.php`, { id, description }, {}).then(res => res.data)
    },
}