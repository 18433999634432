import { workInstance as inst } from "./instance"

export const workAPI = {
    setSuccessTask(objectId: string, objectType: 'equipment' | 'premises' | 'systems' | 'processes') {
        return inst.post(`successTask.php`, { objectId, objectType }, {}).then(res => res.data)
    },
    setCancelTask(objectId: string, objectType: 'equipment' | 'premises' | 'systems' | 'processes') {
        return inst.post(`cancelTask.php`, { objectId, objectType }, {}).then(res => res.data)
    },
    getWorkChanges() {
        return inst.get(`getWorkChanges.php`).then(res => res.data)
    },
}